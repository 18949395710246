import gsap from 'gsap';
import List from 'list.js';

async function initListFilter() {

	gsap.set($('.filters'), {
		height: 0,
	})

	$('.o-button-filter').on('click', function filterButtonClick() {
		$('.filters').toggleClass('open');
		$('.o-button-filter').toggleClass('-active');
		if ($('.filters').hasClass('open')) {
			gsap.set($('.filters'), {
				clearProps: 'all'
			})
			gsap.from($('.filters'), {
				height: 0,
				duration: 0.3
			});
			setTimeout(function fixSiteHeight() {
				window.dispatchEvent(new Event('resize'));
			}, 301);
		} else {
			gsap.to($('.filters'), {
				height: 0,
				duration: 0.3
			});
			setTimeout(function fixSiteHeight() {
				window.dispatchEvent(new Event('resize'));
			}, 301);
		}
	});

	// Programmation complète ------

	const programmationCompleteOptions = {
		valueNames: ['name', 'date', 'jour', 'categorie', 'image', 'time', 'lieu', 'styles', { name: 'link', attr: 'href' }, 'shortDate', 'shortMonth', 'description', 'icon', 'fromTime', 'toTime', 'level'],
		page: 20,
		pagination: {
			innerWindow: 1,
			outerWindow: 1,
		},
		listClass: 'grid',
		item: 'grid-template'
	};

	const programmationCompleteOptions2 = {
		valueNames: ['name', 'date', 'jour', 'categorie', 'image', 'time', 'lieu', 'styles', { name: 'link', attr: 'data-href' }, 'shortDate', 'shortMonth', 'description', 'icon', 'icon', 'fromTime', 'toTime', 'level'],
		page: 20,
		pagination: {
			innerWindow: 1,
			outerWindow: 1,
		},
		listClass: 'list',
		item: 'list-template'
	};

	const getData = async () => {
		let result = []
		let data
		if (window.location.href.indexOf("gala") > -1) {
			data = await fetch("https://billetterie.dansencore.ca/api/activity/public/gala")
		} else if (window.location.href.indexOf("dansexperience") > -1) {
			data = await fetch("https://billetterie.dansencore.ca/api/activity/public/dansexperience")
		} else if (window.location.href.indexOf("spectacles-contemporains") > -1) {
			data = await fetch("https://billetterie.dansencore.ca/api/activity/public/spectacles%20contemporains")
		} else if (window.location.href.indexOf("competitions") > -1) {
			data = await fetch("https://billetterie.dansencore.ca/api/activity/public/competitions")
		} else if (window.location.href.indexOf("vitrines") > -1) {
			data = await fetch("https://billetterie.dansencore.ca/api/activity/public/vitrines")
		} else if (window.location.href.indexOf("classes-de-maitre") > -1) {
			data = await fetch("https://billetterie.dansencore.ca/api/activity/public/classe%20de%20maitre")
		} else if (window.location.href.indexOf("spectacles-gratuits") > -1) {
			data = await fetch("https://billetterie.dansencore.ca/api/activity/public/activite%20gratuites")
			// console.log(data);
		} else if (window.location.href.indexOf("artistes") > -1) {
			data = await fetch("https://billetterie.dansencore.ca/api/artist/public")
		} else if (window.location.href.indexOf("casse") > -1) {
			data = await fetch("https://billetterie.dansencore.ca/api/activity/public/casse-noisette")
			// console.log(data);
		} else {
			data = await fetch("https://billetterie.dansencore.ca/api/activity/public")
		}

		if (data.status === 200) {
			result = await data.json()
		}
		return result
	}

	let activity = await getData();

	// console.log(activity);

	let programmationComplete = new List('programmation_complete', programmationCompleteOptions, activity);

	$('.pagination-nav').append('<div class="btn-next"><svg version="1.1" id="close" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 9 16" style="enable-background:new 0 0 9 16;" xml:space="preserve"> <path d="M1,0c0.3,0,0.5,0.1,0.7,0.3l7,7c0.4,0.4,0.4,1,0,1.4l-7,7c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4L6.6,8L0.3,1.7c-0.4-0.4-0.4-1,0-1.4C0.5,0.1,0.7,0,1,0z"/></svg></div>');
	$('.pagination-nav').prepend('<div class="btn-prev"><svg version="1.1" id="close" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 9 16" style="enable-background:new 0 0 9 16;" xml:space="preserve"> <path d="M8,16c-0.3,0-0.5-0.1-0.7-0.3l-7-7c-0.4-0.4-0.4-1,0-1.4l7-7c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L2.4,8l6.3,6.3c0.4,0.4,0.4,1,0,1.4C8.5,15.9,8.3,16,8,16z"/></svg></div>');

	$('.btn-next').on('click', function clickOnNext() {
		$('.pagination .active').next().trigger('click');
	});
	$('.btn-prev').on('click', function clickOnPrev() {
		$('.pagination .active').prev().trigger('click');
	});

	if (activity.length < 1) {
		$('.no-result').show();
		$('.btn-prev, .btn-next').hide();
	}

	programmationComplete.on('updated', function hideShowNextPrevButtons(list) {
		if (list.matchingItems.length > 0) {
			$('.no-result').hide();
			$('.btn-prev, .btn-next').show();
		} else {
			$('.no-result').show();
			$('.btn-prev, .btn-next').hide();
		}
	});

	// Filters ------

	const filterBtns = $('input[name="filter"]');

	class Filter {
		constructor(field, value) {
			this.field = field;
			this.value = value;
		}
	}

	function FilterBy(data, filters) {
		if (!filters || filters.length < 1) return data
		let filterCat = {};
		filters.forEach(f => {
			if (!filterCat[f.field]) {
				filterCat[f.field] = [];
			}
			filterCat[f.field].push(f.value);
		})
		const fKeys = Object.keys(filterCat);
		fKeys.map(x => {
			data = data.filter(d => {
				return filterCat[x].includes(d[x]);
			})
		})
		return data;
	}

	filterBtns.change(function onFilterChange() {
		let validValues = [];
		let filters = [];
		$('#custom-search-field').val('');
		programmationComplete.search('', ['name', 'category']);

		filterBtns.each(function isFilterChecked() {
			if ($(this).is(':checked')) {
				if ($(this).data("field")) filters.push(new Filter($(this).data("field"), $(this).val()))
			}
		});

		validValues = FilterBy(activity, filters);
		if (validValues.length < 1) {
			$('.no-result').show();
			$('.btn-prev, .btn-next').hide();
		} else {
			$('.no-result').hide();
			$('.btn-prev, .btn-next').show();
		}
		programmationComplete.clear();
		programmationComplete.add(validValues);
		programmationComplete.update();

		setTimeout(function fixSiteHeight() {
			window.dispatchEvent(new Event('resize'));
		}, 301);
	});

	// Search ------

	$('#custom-search-field').keyup(function searchField() {
		var searchString = $(this).val();
		programmationComplete.search(searchString, ['name', 'category']);
	});

	$('.-grid').on('click', () => {
		$('.-list, .list-table').removeClass('-active');
		$('.-grid, .grid').addClass('-active');
		programmationComplete.clear();
		programmationComplete = new List('programmation_complete', programmationCompleteOptions, activity);
		setTimeout(function fixSiteHeight() {
			window.dispatchEvent(new Event('resize'));
		}, 301);
		$('input[type="checkbox"]').prop("checked", false);
		if (activity.length > 0) {
			$('.no-result').hide();
		}

	});

	$('.-list').on('click', () => {
		$('.-grid, .grid').removeClass('-active');
		$('.-list, .list-table').addClass('-active');
		programmationComplete.clear();
		programmationComplete = new List('programmation_complete', programmationCompleteOptions2, activity);
		setTimeout(function fixSiteHeight() {
			window.dispatchEvent(new Event('resize'));
		}, 301);
		$('input[type="checkbox"]').prop("checked", false);
		if (activity.length > 0) {
			$('.no-result').hide();
		}

		// Row Click

		$(".clickable-row").on('click', function () {
			window.location = $(this).data("href");
		});
	});
}

module.exports = {
	initListFilter
}