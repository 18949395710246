// ---- Swiper ----
import Swiper from 'swiper';

async function initSlider() {
// Simple swiper example
const mySwiper = new Swiper('.swiper-container', {
	// Optional parameters
	loop: true,
	grabCursor: true,
	speed: 1000,
	autoplay: {
		delay: 7000
	},

	// If we need pagination
	pagination: {
		el: '.swiper-pagination'
	},

	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next-01',
		prevEl: '.swiper-button-prev-01',
	}
});

// Simple swiper example
const mySwiper2 = new Swiper('.swiper-container2', {
	// Optional parameters
	grabCursor: true,
	speed: 1000,
	spaceBetween: 20,

	breakpoints: {
		// when window width is >= 320px
		1: {
			slidesPerView: 1
		},
		// when window width is >= 480px
		480: {
			slidesPerView: 2
		},
		// when window width is >= 640px
		1000: {
			slidesPerView: 3
		},
		// when window width is >= 640px
		1200: {
			slidesPerView: 4
		}
	},

	// If we need pagination
	pagination: {
		el: '.swiper-pagination'
	},

	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next-02',
		prevEl: '.swiper-button-prev-02'
	}
});

// Simple swiper example
const mySwiper3 = new Swiper('.swiper-container3', {
	// Optional parameters
	loop: true,
	grabCursor: true,
	speed: 1000,

	// If we need pagination
	pagination: {
		el: '.swiper-pagination',
	},

	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next-03',
		prevEl: '.swiper-button-prev-03',
	}
});

// Simple swiper example
const mySwiper4 = new Swiper('.swiper-container4', {
	// Optional parameters
	loop: true,
	grabCursor: true,
	speed: 1000,

	// If we need pagination
	pagination: {
		el: '.swiper-pagination',
	},

	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next-04',
		prevEl: '.swiper-button-prev-04',
	}
});

// Simple swiper example
const mySwiper5 = new Swiper('.swiper-container5', {
	// Optional parameters
	loop: true,
	grabCursor: true,
	speed: 1000,

	// If we need pagination
	pagination: {
		el: '.swiper-pagination',
	},

	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next-05',
		prevEl: '.swiper-button-prev-05',
	}
});

// Simple swiper example
const mySwiper6 = new Swiper('.swiper-container6', {
	// Optional parameters
	loop: false,
	grabCursor: true,
	speed: 1000,
	autoplay: {
		delay: 7000
	},

	breakpoints: {
		// when window width is >= 320px
		1: {
			slidesPerView: 2
		},
		// when window width is >= 640px
		700: {
			slidesPerView: 4
		},
	},

	// If we need pagination
	pagination: {
		el: '.swiper-pagination',
	},

	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next-06',
		prevEl: '.swiper-button-prev-06',
	}
});

// Simple swiper example
const timeline = new Swiper('.swiper-container7', {
	// Optional parameters
	loop: false,
	grabCursor: true,
	speed: 1000,
	autoplay: false,
	breakpoints: {
		// when window width is >= 320px
		1: {
			slidesPerView: 5
		},
		// when window width is >= 640px
		700: {
			slidesPerView: 8
		},
	},
	scrollbar: { el: '.swiper-scrollbar', draggable: true, },
	freeMode: true
});

}

module.exports = {
	initSlider
}