async function initNavigation() {
	// Close banner
	$('.c-banner-close').on('click', () => {
		$('.c-banner').toggleClass('close-notice');
	});

	// Sub Nav Hover
	$('.c-header .menu-item-has-children .c-header_nav_item_link').on('click', function () {
		$('.c-header-search, .c-header-search-window, .c-header-search_bg').removeClass('has-search-open');
		if ($(this).parent().hasClass('-item-hover')) {
			$('.c-header .menu-item-has-children').removeClass('-item-hover');
			$('.c-header_main_nav_wrap').removeClass('-item-hover');
		} else {
			$('.c-header .menu-item-has-children').removeClass('-item-hover');
			$('.c-header_main_nav_wrap').addClass('-item-hover');
			$(this).parent().addClass('-item-hover');
		}
	});

	$('.c-header_main_nav_wrap, .c-header_main, .c-header_top, .c-header_top a, .c-header_main_logo_link, .c-header_search_wrap').on('click', function () {
		$('.c-header .menu-item-has-children').removeClass('-item-hover');
		$('.c-header_main_nav_wrap').removeClass('-item-hover');
	});

	// Full Screen nav toggle
	$('.c-header_toggler').on('click', function openFullScreenNav() {
		$(this).add('.c-full-screen-nav').toggleClass('is-active');
	});

	$('.c-full-screen-nav_close').on('click', function openFullScreenNav() {
		$('.c-header_toggler, .c-full-screen-nav').removeClass('is-active');
	});

	// Media Query
	function myFunction(x) {
		if (x.matches) { // If media query matches
			$('.c-full-screen-nav .c-header_nav_item.menu-item-has-children .c-header_nav_item_link').unbind('mouseenter mouseleave');
			$('.c-full-screen-nav .c-header_nav_item.menu-item-has-children .c-header_nav_item_link').on('click', function hoverLinks() {
				$('.c-full-screen-nav .c-header_nav_item.menu-item-has-children').removeClass('-item-hover');
				$(this).parent().addClass('-item-hover');
				$('.c-full-screen-nav').addClass('-item-hover');
			});

			$('.c-full-screen-nav .c-header_nav_item:not(.menu-item-has-children) .c-header_nav_item_link, .c-full-screen-nav_logo_wrap a, .c-full-screen-nav_close').on('click', function hoverClose() {
				$('.c-full-screen-nav .c-header_nav_item.menu-item-has-children').removeClass('-item-hover');
				$('.c-full-screen-nav').removeClass('-item-hover');
			});

			$('.c-panel-back-button').on('click', () => {
				$('.c-full-screen-nav .c-header_nav_item.menu-item-has-children').removeClass('-item-hover');
				$('.c-full-screen-nav').removeClass('-item-hover');
			});
		} else {
			$('.c-full-screen-nav .c-header_nav_item.menu-item-has-children .c-header_nav_item_link').on({
				mouseenter: function hoverLinks() {
					$('.c-full-screen-nav .c-header_nav_item.menu-item-has-children').removeClass('-item-hover');
					$(this).parent().addClass('-item-hover');
					$('.c-full-screen-nav').addClass('-item-hover');
				}
			});

			$('.c-full-screen-nav .c-header_nav_item:not(.menu-item-has-children) .c-header_nav_item_link, .c-full-screen-nav_logo_wrap a, .c-full-screen-nav_close').on({
				mouseenter: function hoverClose() {
					$('.c-full-screen-nav .c-header_nav_item.menu-item-has-children').removeClass('-item-hover');
					$('.c-full-screen-nav').removeClass('-item-hover');
				}
			});

			$('.c-panel-back-button').on('click', () => {
				$('.c-full-screen-nav .c-header_nav_item.menu-item-has-children').removeClass('-item-hover');
				$('.c-full-screen-nav').removeClass('-item-hover');
			});
		}
	}

	const x = window.matchMedia('(max-width: 700px)');
	myFunction(x); // Call listener function at run time
	x.addListener(myFunction); // Attach listener function on state changes

	$('.c-full-screen-nav .c-header_nav_item:not(.menu-item-has-children) .c-header_nav_item_link, .c-full-screen-nav .c-header_top_user_link a, .c-full-screen-nav .c-header_top_social_wrap a, .c-full-screen-nav .c-header_top_language_wrap a, .c-full-screen-nav .c-full-screen-nav_list_wrap .c-full-screen-nav_list .c-header_nav_item .c-header_nav_sub-menu .c-header_nav_sub-menu_item .c-header_nav_sub-menu_item_link, .c-full-screen-nav .c-full-screen-nav_logo_wrap a').on('click', () => {
		$('.c-header_toggler, .c-full-screen-nav').removeClass('is-active');
		$('.c-full-screen-nav .c-header_nav_item.menu-item-has-children').removeClass('-item-hover');
		$('.c-full-screen-nav').removeClass('-item-hover');
	});

	// Search toggle class
	$('.c-header_search_wrap').on('click', () => {
		$('.c-header-search, .c-header-search-window, .c-header-search_bg').toggleClass('has-search-open');
		setTimeout(() => {
			$('#search').select();
		}, 600);
	});

	$('.c-search-icon').on('click', () => {
		$('.o-search-button').click();
	});

	$('.c-header-search_bg, .c-header_main_logo_link, .c-header_top, .c-header_top a, .c-header_nav_item a, .c-header_main_nav_wrap, .c-header_main, .c-close-icon').on('click', function () {
		$('.c-header-search, .c-header-search-window, .c-header-search_bg').removeClass('has-search-open');
	});
}

module.exports = {
	initNavigation
}