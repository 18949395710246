// ---- Gsap ----
import gsap from 'gsap';

gsap.config({
	nullTargetWarn: false
});

async function initButtons() {
	$('.o-button').each(function findAllButtons() {
		const buttonTl = gsap.timeline({
			paused: true
		});
		buttonTl
			.addLabel('start')
			.to($(this).find('.o-button-text'), {
				x: -13,
				ease: 'power1.inOut',
				duration: 0.32
			})
			.fromTo($(this).find('.o-button-hover'), {
				autoAlpha: 0
			}, {
				autoAlpha: 1,
				ease: 'power1.inOut',
				duration: 0.16
			}, 'start')
			.fromTo($(this).find('.-left.-orange-bg, .-right.-orange-bg'), {
				fill: '#231F20'
			}, {
				fill: '#F79433',
				ease: 'power1.inOut',
				duration: 0.16
			}, 'start')
			.addLabel('mid')
			.fromTo($(this).find('.o-button-arrow-right'), {
				autoAlpha: 0,
				// scale: 0.75,
				xPercent: -25
			}, {
				autoAlpha: 1,
				// scale: 1,
				xPercent: 0,
				ease: 'power1.inOut',
				duration: 0.12
			}, 'mid-=0.12');

		$(this).on({
			mouseenter: () => {
				buttonTl.play();
			},
			mouseleave: () => {
				buttonTl.reverse();
			}
		});
	});
}

module.exports = {
	initButtons
}