async function initTranslate() {

	function setCookie(b, h, c, f, e) {
		var a;
		if (c === 0) {
			a = ""
		} else {
			var g = new Date();
			g.setTime(g.getTime() + (c * 24 * 60 * 60 * 1000));
			a = "expires=" + g.toGMTString() + "; "
		}
		var e = (typeof e === "undefined") ? "" : "; domain=" + e;
		document.cookie = b + "=" + h + "; " + a + "path=" + f + e
	}

	function getCookie(d) {
		var b = d + "=";
		var a = document.cookie.split(";");
		for (var e = 0; e < a.length; e++) {
			var f = a[e].trim();
			if (f.indexOf(b) == 0) {
				return f.substring(b.length, f.length)
			}
		}
		return ""
	}

	//Using jQuery

	$(".-es").on("click", function () {
		if (googTrans == '/fr/es') {
			setCookie("googtrans", "", 0, "/", "dansencore.ca");
			setCookie("googtrans", "", 0, "/");
			alert('El contenido de la página será traducido por Google Translation. \nEs posible que parte del contenido no se traduzca correctamente.');
			location.reload();
		} else {
			setCookie("googtrans", "/fr/es", 0, "/", "dansencore.ca");
			setCookie("googtrans", "/fr/es", 0, "/");
			alert('El contenido de la página será traducido por Google Translation. \nEs posible que parte del contenido no se traduzca correctamente.');
			location.reload()
		}
	});

	$(".-fr").on("click", function () {
		if (googTrans == '/fr/fr') {
			setCookie("googtrans", "", 0, "/", "dansencore.ca");
			setCookie("googtrans", "", 0, "/");
			location.reload();
		} else {
			setCookie("googtrans", "/fr/fr", 0, "/", "dansencore.ca");
			setCookie("googtrans", "/fr/fr", 0, "/");
			location.reload()
		}
	});

	$(".-en").on("click", function () {
		if (googTrans == '/fr/en') {
			setCookie("googtrans", "", 0, "/", "dansencore.ca");
			setCookie("googtrans", "", 0, "/");
			alert('Page content will be translated by Google Translation. \nSome content may not be translated properly.');
			location.reload();
		} else {
			setCookie("googtrans", "/fr/en", 0, "/", "dansencore.ca");
			setCookie("googtrans", "/fr/en", 0, "/");
			alert('Page content will be translated by Google Translation. \nSome content may not be translated properly.');
			location.reload()
		}
	});

	var googTrans = getCookie('googtrans');

	function downloadJSAtOnload() {
		var i;
		var paths = new Array(
			'//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
		);
		for (i in paths) {
			if (typeof paths[i] !== 'string') {
				console.log(typeof paths[i]);
				continue;
			}
			var element = document.createElement("script");
			element.src = paths[i];
			document.body.appendChild(element);
		}
	}

}

module.exports = {
	initTranslate
}