// ---- Accordion JS ----
import Accordion from 'accordion-js';

async function initAccordions() {
	// Accordion JS
	const accordions = Array.from(document.querySelectorAll('.o-accordion-container'));

	if (accordions) {
		let count = 0;
		let id = null;

		accordions.forEach((item) => {
			id = `accordion${count}`;
			item.id = id;

			const accordion = new Accordion(`#${id}`, {
				duration: 300,
				showItem: false,
				// onToggle: function (currentElement, allElements) {
				// 	console.log(currentElement);
				// },
				elementClass: 'o-accordion',
				questionClass: 'o-accordion-question',
				answerClass: 'o-accordion-answer'
			});
			count += 1;
		});
	}

	$('.o-accordion-question__wrap').on('click', () => {
		setTimeout(function fixSiteHeight() {
			window.dispatchEvent(new Event('resize'));
		}, 301);
	});

	// Accordions tabs
	$('.o-accordion-tag').on('click', function changeAccordion() {
		const currentSlide = $(this).data('faq');
		$('.o-accordion-tag, .o-accordion-container').removeClass('-active');
		$(this).addClass('-active');
		$('.o-accordion-container').each(function activateCurrentSlide() {
			if ($(this).data('faq') === currentSlide) {
				$(this).addClass('-active');
			}
		});
		setTimeout(function fixSiteHeight() {
			window.dispatchEvent(new Event('resize'));
		}, 100);
	});
}

module.exports = {
	initAccordions
}