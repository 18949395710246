// --------------
// Import [START]
// --------------
import 'babel-polyfill';

// ---- jQuery ----
import $ from 'jquery';

// ---- Locomotive scroll ----
import LocomotiveScroll from 'locomotive-scroll';

// ---- Barba.js ----
import barba from '@barba/core';

// ---- Gsap ----
import gsap from 'gsap';
import {
	DrawSVGPlugin
} from 'gsap/DrawSVGPlugin';
import {
	ScrollTrigger
} from 'gsap/ScrollTrigger';
import {
	SplitText
} from 'gsap/SplitText';

// Import external files
const listFilter = require('./_listFilter');
const translate = require('./_translate');
const navigation = require('./_navigation');
const accordions = require('./_accordions');
const slider = require('./_slider');
const buttons = require('./_buttons');
const animations = require('./_animations');

// ------------
// Import [END]
// ------------

// ---- Register GSAP plugins ----
gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, SplitText);
gsap.config({
	nullTargetWarn: false
});

navigation.initNavigation();

//---- Define Locomotive Scroll ----
const scroll = new LocomotiveScroll({
	el: document.querySelector('#js-scroll'),
	smooth: true
});

// --------------------
// Init scripts [START]
// --------------------

function initScripts() {

	// Call external files functions
	translate.initTranslate();
	listFilter.initListFilter();
	accordions.initAccordions();
	slider.initSlider();
	buttons.initButtons();
	animations.initAnimations();

	// Loco Scroll on Scroll
	scroll.on('call', (value) => {
		if (value === 'startCounting') {
			$('.-number-stroke').each(function countUp() {
				var $this = $(this);
				var countTo = $this.attr('data-count');
				$({
					countNum: $this.text()
				}).animate({
					countNum: countTo
				}, {
					duration: 2000,
					easing: 'linear',
					step: function countUp2() {
						$this.text(Math.floor(this.countNum).toLocaleString('fr'));
					},
					complete: function countUp3() {
						$this.text((this.countNum).toLocaleString('fr'));
					}
				});
			});
		}
	});

	// Dev durable ScrollTo
	$('a[href*="#dev"]').each(function findDevAnchor() {
		$(this).on('click', function scrollToDev() {
			scroll.scrollTo('#dev');
		});
	});

	// fdhgsfjkdsfsd
	$('.quform-form').submit(function onFormSubmit() {
		setTimeout(function quformfixSiteHeight() {
			window.dispatchEvent(new Event('resize'));
		}, 2000);
	});
}

// ------------------
// Init scripts [END]
// ------------------

// ---- Prevent Barba hard reload when already on the page ----

const links = document.querySelectorAll('a[href]');
const cbk = function cbkFunction(e) {
	if (e.currentTarget.href === window.location.href) {
		e.preventDefault();
		e.stopPropagation();
	}
};

for (let i = 0; i < links.length; i++) {
	links[i].addEventListener('click', cbk);
}

// ---- Hide scroll bar if at top or bottom ----
function hideScrollBar() {
	scroll.on('scroll', (e) => {
		const windowHeight = $(window).height();
		const pageHeight = $('.o-scroll').height();

		if ($('.c-scrollbar').length) {
			if (e.scroll.y === 0) {
				$('.c-scrollbar_thumb').addClass('is-top');
			} else {
				$('.c-scrollbar_thumb').removeClass('is-top');
			}
			if (e.scroll.y >= (pageHeight - windowHeight - 1)) {
				$('.c-scrollbar_thumb').addClass('is-bottom');
			} else {
				$('.c-scrollbar_thumb').removeClass('is-bottom');
			}
		}

		if (e.scroll.y > 100) {
			$('header, .c-header-search-window, .c-header_nav_sub-menu').addClass('minimize');
		} else {
			$('header, .c-header-search-window, .c-header_nav_sub-menu').removeClass('minimize');
		}
	});
}

// -----------------------------------
// gsap/Loco Scroll Animations [START]
// -----------------------------------
function gsapLocoAnimations() {
	scroll.on('scroll', ScrollTrigger.update);
	ScrollTrigger.scrollerProxy('.o-scroll', {
		scrollTop(value) {
			return arguments.length ? scroll.scrollTo(value, 0, 0) : scroll.scroll.instance.scroll.y;
		},
		getBoundingClientRect() {
			return {
				top: 0,
				left: 0,
				width: window.innerWidth,
				height: window.innerHeight
			};
		},
		pinType: document.querySelector('.o-scroll').style.transform ? 'transform' : 'fixed'
	});

	// ---- [HOME] Draw svg of Dancing 1 ----
	$('.o-section-dancing svg path').each(function drawTheLines() {
		gsap.from($(this), {
			scrollTrigger: {
				trigger: $(this),
				scroller: '.o-scroll',
				scrub: false,
				pin: false,
				start: 'top 95%'
			},
			drawSVG: '0%',
			ease: 'power2.inOut',
			duration: 4
		});
	});

	$('.o-section-history svg path').each(function drawTheLines() {
		gsap.from($(this), {
			scrollTrigger: {
				trigger: $(this),
				scroller: '.o-scroll',
				scrub: false,
				pin: false,
				start: 'top 95%'
			},
			drawSVG: '0%',
			ease: 'power2.inOut',
			duration: 4
		});
	});

	// ---- [HOME] Reveal Stats ----
	if ($('.-stats-section .o-stats__wrap').length) {
		const homeRevealStatsTl = gsap.timeline({
			scrollTrigger: {
				trigger: '.-stats-section',
				scroller: '.o-scroll',
				scrub: false,
				pin: false,
				start: 'top 95%'
			}
		});

		homeRevealStatsTl
			.from('.-stats-section .o-stats__wrap', {
				yPercent: 50,
				autoAlpha: 0,
				stagger: 0.16,
				duration: 0.48,
				delay: 0.48,
				ease: 'circ.out'
			});
	}

	ScrollTrigger.addEventListener('refresh', () => scroll.update());

	ScrollTrigger.refresh();
}

// ---------------------------------
// gsap/Loco Scroll Animations [END]
// ---------------------------------

// ---- Delay function ----
function delay(n) {
	return new Promise((done) => {
		setTimeout(() => {
			done();
		}, n);
	});
}

// ---- Function to refresh Loco container height ----
function fixLoco() {
	window.dispatchEvent(new Event('resize'));
}

// ---- Function to add and remove the page transition screen ----
function pageTransition() {
	const pageTransitionTl = gsap.timeline();
	pageTransitionTl.to('section, footer', {
		duration: 0.5,
		opacity: 0
	});
}

// ---- Function to animate the content of each page ----
function contentAnimation() {
	const contentAnimationtl = gsap.timeline();
	contentAnimationtl.from('section, footer', {
		duration: 0.5,
		opacity: 0
	});
	contentAnimationtl.eventCallback('onComplete', fixLoco);
}

// ---- Basic default transition ----
barba.init({
	sync: true,
	prefetchIgnore: true,
	transitions: [{
		async leave() {
			const done = this.async();
			pageTransition();
			await delay(500);
			done();
		},
		async enter() {
			contentAnimation();
		},
		async once() {
			contentAnimation();
		}
	}]
});

// ----------------------
// Barba.js Hooks [START]
// ----------------------

// ---- Barba Global hooks ----
barba.hooks.before(() => {
	$('html, .o-loading').addClass('is-loading');
	scroll.destroy();
	$('header, .c-header-search-window, .c-header_nav_sub-menu').removeClass('minimize');
});

barba.hooks.after(() => {
	$('html').removeClass('is-loading');
	$('.c-scrollbar_thumb').addClass('is-top');
	scroll.init();
	hideScrollBar();
	gsapLocoAnimations();
	initScripts();
});

barba.hooks.afterOnce(() => {
	animations.initPreloader();
	hideScrollBar();
	gsapLocoAnimations();
	initScripts();
});

barba.hooks.enter(() => {
	// Reset header shrink on new page load
	$('.o-loading').removeClass('is-loading');

	if ($('.quform').length) {
		const $script = $('#quform-js');
		$.ajax({
			url: $script.attr('src'),
			cache: true,
			dataType: 'script',
			success: function () {
				$script.trigger('load');
			}
		});
	}
});

// --------------------
// Barba.js Hooks [END]
// --------------------