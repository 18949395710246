// ---- Gsap ----
import gsap from 'gsap';
import {
	DrawSVGPlugin
} from 'gsap/DrawSVGPlugin';
import {
	ScrollTrigger
} from 'gsap/ScrollTrigger';
import {
	SplitText
} from 'gsap/SplitText';

// ---- Register GSAP plugins ----
gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, SplitText);
gsap.config({
	nullTargetWarn: false
});

async function initAnimations() {
	// ---- Home slider enter animation ----
	if ($('.o-section-slider-hero').length) {
		const homeSliderTl = gsap.timeline();
		const homeSliderSplit = new SplitText('.o-section-slider-hero h1', {
			type: 'words'
		});

		homeSliderTl.addLabel('start')
			.from('.o-section-slider-hero .swiper-img', {
				scale: 1.25,
				duration: 1.48,
				ease: 'circ.out'
			})
			.from('.o-section-slider-hero .-small-slider-title', {
				yPercent: 50,
				autoAlpha: 0,
				duration: 0.48,
				delay: 0.48,
				ease: 'circ.out'
			}, 'start')
			.from(homeSliderSplit.words, {
				yPercent: 50,
				autoAlpha: 0,
				stagger: 0.04,
				duration: 0.48,
				delay: 0.48,
				ease: 'circ.out'
			}, 'start')
			.from('.o-section-slider-hero .o-button', {
				autoAlpha: 0,
				duration: 0.48,
				delay: 0.84,
				ease: 'circ.out'
			}, 'start');
	}

	// Timeline
	$('.c-timeline-dot').on('click', function changeTimelineSlide() {
		const currentSlide = $(this).data('slide');
		$('.c-timeline-dot, .c-timeline-content_slide').removeClass('-active');
		$(this).addClass('-active');
		$('.c-timeline-content_slide').each(function activateCurrentSlide() {
			if ($(this).data('slide') === currentSlide) {
				$(this).addClass('-active');
			}
		});
	});
}

async function initPreloader() {
	// Preloader
	const preloaderTl = gsap.timeline();
	preloaderTl
		// .fromTo('.o-preloader .c-loader', {
		// 	autoAlpha: 0
		// },{
		// 	autoAlpha: 1,
		// 	duration: 0.32,
		// 	delay: 0.16
		// })
		// .fromTo('.o-preloader #dance_1 path', {
		// 	drawSVG:'0%',
		// 	opacity: 1
		// }, {
		// 	drawSVG:'100%',
		// 	opacity: 1,
		// 	duration: 4,
		// 	ease: 'power2.inOut'
		// }, '-=.16')
		// .to('.o-preloader #dance_1 path, .o-preloader .c-loader', {
		// 	autoAlpha: 0,
		// 	duration: 0.32
		// })
		.to('.o-preloader', {
			autoAlpha: 0,
			duration: 0.96,
			delay: 0.32
		})
		.set('.o-preloader', {
			display: 'none'
		});
}

module.exports = {
	initAnimations, initPreloader
}